import React, { FC, } from 'react'; 
import { Story } from '../../shared/cms-data-setup';
import { pos, size, boxPad, maxHeight, yellow, } from '../../shared/styles';
import './Text.css';

export const Text: FC<{
    story: Story,
}> = ({ story }) => {
    const { title: titleNorwegian, body, source, credit } = story;
    
    return (
        <div style={{
            ...pos(1410, 0),
            ...size(510, maxHeight),
            ...boxPad(42, 36),
            backgroundColor: 'black',
        }}
        className='StoryDisplay_Text'>
            <div style={{
                fontSize: 36,
                fontWeight: 'bold',
                color: yellow,
            }}>
                {titleNorwegian}
            </div>
            <div style={{
                fontSize: 25,
                color: yellow,
                marginBottom: 51,
            }}
                dangerouslySetInnerHTML={{ __html: body+'' }}>
            </div>

            <div style={{
                fontSize: 14,
                color: yellow,
                lineHeight: 1.5,
                overflow: 'visible',
                fontStyle: 'italic',
                fontWeight: 600,
            }} className="StoryDisplay_Credits">
                <div>{source}</div>
                <div>{credit}</div>
            </div>
        </div>
    );
};