import React, { FC } from 'react';
import { pos, yellow } from '../../shared/styles';
import { xSpace, ySpace } from './Common';
import { useSpring, animated } from 'react-spring';
import './Progress.css';

export const Progress: FC<{
    active: boolean,
    duration: number,
    text: string,
    goLeft: Function,
    goRight: Function
}> = ({ active, duration, text, goLeft, goRight }) => {

    const props = useSpring({
        from: { transform: 'translateX(-100%)', },
        transform: active ? 'translateX(0)' : 'translateX(-100%)',
        config: { duration: duration * 1000, },
    });

   

    return (

        <div
            style={{
                ...pos(-xSpace, -ySpace),
                width: 120,
                fontSize: 50,
                fontWeight: 100,
                textAlign: 'center',
                display: 'flex',
                flexFlow: 'row nowrap'
            }}
        >

            <div
                style={{
                    cursor: 'pointer'
                }}
                className="knapp" onClick={() => goLeft()}><div className='arrow-left'></div></div>
            <div>
                <div className='text'>{text}</div>

                <div style={{
                    position: 'relative',
                    height: 4,
                    width: 120,
                    backgroundColor: 'white',
                    overflow: 'hidden',
                }}>
                    <animated.div style={{
                        backgroundColor: yellow,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        ...props
                    }}
                    >&nbsp;</animated.div>
                </div>

            </div>
            <div
                style={{
                    cursor: 'pointer'
                }}

                className="knapp" onClick={() => goRight()}><div className='arrow-right'></div></div>
        </div>



    );
};