import { QueryFetcher, _base_private_, FieldSetDefinition, ResultType } from './base';
import { useState, useEffect } from 'react';
import { uidResult } from './types';

const { fieldSetQueryExpression, dataFromFieldSet, filterExpr, } = _base_private_;

/**
 * Convention to specify typeHandle to match server
 * 
 * class MySectionEntry extends SectionEntry {
 *     typeHandle = 'myCraftSectionEntry' as const;
 * 
 *     @field(text())
 *     someTextField!: textResult;
 * }
 */

export interface SectionEntry extends FieldSetDefinition {
    readonly typeHandle: string; // as const
};

const singleEntrySectionQueryExpression = (
    sectionHandle: string,
    sectionEntryDefinition: SectionEntry,
) => `  ...on ${sectionHandle}_${sectionEntryDefinition.typeHandle}_Entry {
            typeHandle${fieldSetQueryExpression(sectionEntryDefinition)}
        }`;

const entrySectionsQueryExpression = (
    sectionHandle: string,
    sectionEntryDefinitions: SectionEntry[]
) => sectionEntryDefinitions.reduce((prev, curr) => `${prev}
    ${singleEntrySectionQueryExpression(sectionHandle, curr)}`, '');

const sectionQueryExpression = ({ sectionHandle, sectionEntryDefinitions, filter }: {
    sectionHandle: string,
    sectionEntryDefinitions: SectionEntry[],
    filter?: { [argName: string]: string | undefined }, 
}) => {
    const params = filterExpr({
        section: sectionHandle,
        ...filter
    });

    
    return `{
        entries${params} {${entrySectionsQueryExpression(sectionHandle, sectionEntryDefinitions)}
        }
    }`;
};

export const entriesFromServer = <S extends SectionEntry>({ sectionHandle, sectionEntries: entriesDefinitions, fetcher, filter, token }: {
    sectionHandle: string,
    sectionEntries: (new () => S)[],
    fetcher: QueryFetcher,
    // Filter query to something more specific
    filter?: { [argName: string]: string | undefined },
    // Preview token, used when integrating with Craft preview
    token?: string,
}) => new Promise<S[]>((resolve, reject) => {
    const sectionEntryDefinitions = entriesDefinitions.map(e => new e());

    const query = sectionQueryExpression({
        sectionHandle, sectionEntryDefinitions,
        filter: {
            ...(
                // If token is not provided make sure it's live
                // (requesting certain fields, such as lastUpdated
                // can make craft start giving you entries
                // that are not published, this is to avoid that)
                token === undefined ?
                { status: "live" }
                : {}
            ),
            ...filter,
        }
    });
    
    fetcher.get({
        query, token,
    }).then(data => {
        if (Array.isArray(data.entries)) {
            if (data.entries.length < 1) {
                console.warn(`entriesFromServer ${sectionHandle}: data.entries had no entries`)
            };
            
            const entryDefintionDictionary: {
                [typeHandle: string]: S,
            } = Object.fromEntries(sectionEntryDefinitions.map(e => [e.typeHandle, e]));

            Promise.all(data.entries
                .filter((serverData: any) => {
                    if (serverData.typeHandle === undefined) {
                        console.warn('entriesFromServer: encountered entry with missing typeHandle');
                        return false;
                    } else { return true; }   
                })
                .map((serverData: any) => {
                    const typeHandle = serverData.typeHandle;
                    const fieldSetDefinition = entryDefintionDictionary[typeHandle];
                    if (fieldSetDefinition !== undefined) {
                        return dataFromFieldSet({
                            fieldSetDefinition,
                            serverData,
                        });
                    } else {
                        return Promise.reject(`entriesFromServer: typeHandle ${typeHandle+''} not defined locally`);
                    }
            }))
            .then(value => resolve(value as S[]))
            .catch(reason => reject(`entriesFromServer: ${reason+''}`))
        } else {
            reject(`entriesFromServer ${sectionHandle}: data.entries was not array`)
        }
    }).catch(reason => reject(`entriesFromServer: ${reason+''}`));
});


type EntriesFromUse<S extends SectionEntry> = ResultType<S[]>;
export const useEntriesFromServer = <S extends SectionEntry>({ sectionHandle, sectionEntries, fetcher, updateEveryMin }: {
    sectionHandle: string,
    sectionEntries: (new () => S)[],
    fetcher: QueryFetcher,
    updateEveryMin?: number,
}): EntriesFromUse<S> => {
    const [entries, setEntries] = useState<EntriesFromUse<S>>({ status: 'loading' });

    useEffect(() => {
        const update = () => {
            console.log('update');
            
            entriesFromServer<S>({
                sectionHandle,
                sectionEntries,
                fetcher
            }).then(data => setEntries({
                status: 'ok',
                data,
            })).catch(error => setEntries(prev => {
                if (prev.status === 'ok') {
                    console.log('useEntriesFromServer: entry already set so will only signal error in console');
                    console.error(error);
                    return prev;
                } else {
                    return {
                        status: 'error',
                        error,
                    };
                }
            }));
        };

        if (updateEveryMin !== undefined) {
            if (updateEveryMin > 0) {
                const intervalMS = updateEveryMin * 60000;

                // Update immedieatly and…
                update();

                // Every specified minute
                const id = setInterval(update, intervalMS);
                return () => clearInterval(id);
            } else {
                setEntries({
                    status: 'error',
                    error: `useEntriesFromServer: invalid updateEveryMin value: ${updateEveryMin}, must be > 0`,
                });
            }
        } else {    
            update();
        }
    }, [sectionHandle, sectionEntries, fetcher, updateEveryMin]);

    return entries;
}

// Using preview required having uid in request.
type SectionEntryForPreview = SectionEntry & { uid: uidResult };

export const previewEntryFromServer = async <S extends SectionEntryForPreview>({ sectionHandle, sectionEntries, fetcher, uid, token }: {
    sectionHandle: string,
    sectionEntries: (new () => S)[],
    fetcher: QueryFetcher,
    // Uid to find it
    uid: string,
    // Preview token, used when integrating with Craft preview
    token: string,
}): Promise<S> => {
    const entries = await entriesFromServer({
        sectionHandle, sectionEntries, fetcher, token,
    });

    // The most logical thing would be to send a filter
    // with the preview UID to server, but that always
    // fails for some reason, so instead we just pull all the records
    // and filter for the specific entry we want locally

    const entry = entries.filter(e => e.uid === uid);

    if (entries.length > 0) {
        return entry[0];
    } else {
        return Promise.reject('previewEntryFromServer: No data returned');
    }
}

type PreviewEntryFromUse<S extends SectionEntryForPreview> = ResultType<S>;
export const usePreviewEntryFromServer = <S extends SectionEntryForPreview>({ sectionHandle, sectionEntries, fetcher, uid, token }: {
    sectionHandle: string,
    sectionEntries: (new () => S)[],
    fetcher: QueryFetcher,
    uid: string,
    token: string,
}) => {
    const [entry, setEntry] = useState<PreviewEntryFromUse<S>>({ status: 'loading' });

    useEffect(() => {
        previewEntryFromServer<S>({
            sectionHandle,
            sectionEntries,
            fetcher,
            uid,
            token,
        }).then(data => setEntry({
            status: 'ok',
            data,
        })).catch(error => setEntry({
            status: 'error',
            error,
        }));
    }, [sectionHandle, sectionEntries, fetcher, uid, token]);

    return entry;
}

export const _section_private_ = {
    singleEntrySectionQueryExpression,
    entrySectionsQueryExpression,
    sectionQueryExpression,
}