import React from 'react';
import './App.css';
import { appStyle } from './shared/styles';
import { SlideshowMode } from './components/SlideshowMode';
import { PreviewMode } from './components/PreviewMode';

const App: React.FC = () => {
  const params = new URLSearchParams(window.location.search);

  const mode = (() => {
    const token = params.get('token');
    const uid = params.get('uid');
    if (token !== null && uid !== null) {
      return <PreviewMode token={token} uid={uid} />
    } else {
      return <SlideshowMode />;
    }
  })();

  return (
    <div style={appStyle} className="unselectable">
      {mode}
    </div>
  );
}

export default App;
