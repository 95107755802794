import React, { FC, } from 'react'; 
import { Story } from '../../shared/cms-data-setup';
import { pos, size, stretch, maxHeight } from '../../shared/styles';
import { mediaWidth } from './Common';
import { useSpring, animated } from 'react-spring';

export const Image: FC<{
    story: Story,
    active: boolean,
}> = ({ story, active }) => {
    const { durationSeconds, visualMedia } = story;

    const props = useSpring({
        from: { transform: 'scale(1)', },
        transform: active ? 'scale(1.25)' : 'scale(1)',
        config: { duration: durationSeconds*1000, },
    });

    // Config stays here?

    return (
        <div style={{
            ...pos(0, 0),
            ...size(mediaWidth, maxHeight),
            overflow: 'hidden',
        }}>
        {
            <animated.div 
                style={{
                    ...stretch,
                    backgroundImage: `url(${visualMedia})`,
                    backgroundSize: 'cover',
                    ...props,
                }}>&nbsp;</animated.div>
        }
        </div>
    );
};