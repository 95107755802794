import React, { FC, } from 'react'; 
import './PreviewMode.css';
import { usePreviewEntryFromServer } from '../api/cms-bridge/section';
import { Slide, sectionHandle, sectionEntries, fetcher } from '../shared/cms-data-setup';
import { SlideDisplay } from './SlideDisplay';

export const PreviewMode: FC<{
    token: string,
    uid: string,
}> = ({ token, uid }) => {    
    const entry = usePreviewEntryFromServer<Slide>({
        sectionHandle, sectionEntries, fetcher,
        token, uid,
    });    
    
    switch(entry.status) {
    case 'loading':
        return <div>Loading preview…</div>;
    case 'ok':
        return <SlideDisplay
                    slide={entry.data}
                    active
                    progressText='X/X'
                    goLeft={() => ""}
                    goRight={() => ""}
                />;
    case 'error':
        return <div>Error: {entry.error}</div>;
    }
};