import React, { FC, } from 'react'; 
import { Poster } from '../shared/cms-data-setup';
import { stretch, maxHeight, maxWidth, } from '../shared/styles';
import { animated, useSpring, } from 'react-spring'
import { useActiveChange } from '../api/util-hooks';

export const PosterDisplay: FC<{
    poster: Poster,
    active: boolean,
}> = ({ poster, active }) => {
    const { image, text, verticalAlign  } = poster;

    const change = useActiveChange(active, false);

    const toProps = (() => {
        if (change === 'enter-active') { return {
            opacity: 1,
            transform: `translateX(0px)`,
        }} else if (change === 'leave') { return {
            opacity: 0,
            transform: `translateX(-${maxWidth}px)`,
        }} else /*  */ { return {
            opacity: 0,
            transform: `translateX(${maxWidth}px)`,
        }}
    })();

    const imgProps = useSpring(toProps);
    const txtProps = useSpring({
        ...toProps,
        // Make txt transition slightly different timed
        // for a sorta parallax effect
        config: { friction: 30, }
    })


    return (<>
    {
        <animated.div style={{
            ...stretch,
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            ...imgProps,
        }}>&nbsp;</animated.div>
    }

    {
        <animated.div style={{
            ...stretch,
            lineHeight: `${maxHeight}px`,
            ...txtProps,
        }}>
            <span style={{ 
                display: 'inline-block',
                verticalAlign,
                lineHeight: 1,
                width: maxWidth,
                // To control font sizes of
                // contents look at html in App.css
            }}
            dangerouslySetInnerHTML={{ __html: text+'' }}
            ></span>
        </animated.div>
    }
    </>);

    // const controls = useAnimation();
    // useEffect(() => {
    //     if (active) {
    //         // BEFORE ENTER
    //         controls.set({
    //             zIndex: zTopMost,
    //             x: '100%',
    //         });

    //         // ANIMATE IN
    //         controls.start({
    //             x: '0%',
    //             transition: {
    //                 duration: transitionTimeS,
    //                 ease: 'easeOut',
    //             },
    //         });

    //     } else {
    //         // BEFORE NEXT ENTER
    //         controls.set({ 
    //             zIndex: zNormal, 
    //         });

    //         setTimeout(() => {
    //             // AFTER NEXT HAS ENTERED
    //             controls.set({ 
    //                 x: '100%', 
    //             });
    //         }, transitionTimeS*1000);
    //     }
    // }, [controls, active]);

    // return (
    //     <motion.div
    //         style={{ 
    //             ...stretch,
    //             zIndex: zNormal,
    //          }}
    //         // animate={controls}
    //         variants={{

    //         }}
    //     >
            // <div style={{
            //     ...stretch,
            //     backgroundImage: `url(${image})`,
            //     backgroundSize: 'cover',
            // }}>&nbsp;</div>

            // <div 
            //     style={{
            //         ...stretch,
            //         lineHeight: `${maxHeight}px`,
            //     }}>
            //         <span style={{ 
            //             display: 'inline-block',
            //             verticalAlign,
            //             lineHeight: 1,
            //             width: maxWidth,
            //             // To control font sizes of
            //             // contents look at html in App.css
            //         }}
            //         dangerouslySetInnerHTML={{ __html: text }}
            //         ></span>
            //     </div>
    //         </motion.div>
    // )
};