import { CraftTypeSettings, CraftType } from './base';

export type textResult = string;
export const text = (settings?: CraftTypeSettings): CraftType => ({
    queryExpression: name => name,
    extractData: (serverData, name) => new Promise<textResult>((resolve, reject) => {
        if (serverData === undefined) {
            if (settings !== undefined && settings.optional) {
                resolve(undefined);
            } else {
                reject(`text: serverData missing for non-optional field (${name})`);
            }
        } else if (typeof serverData === 'string') {
            resolve(serverData);
        } else {
            if (settings !== undefined && settings.optional) {
                resolve(`${name} missing`);
            } else {
                reject(`text: serverData was not a string (${name})`);
            }
        }
    }),
});

export type floatResult = number;
export const float = (settings?: CraftTypeSettings): CraftType => ({
    queryExpression: name => name,
    extractData: (serverData, name) => new Promise<floatResult>((resolve, reject) => {
        if (serverData === undefined) {
            if (settings !== undefined && settings.optional) {
                resolve(undefined);
            } else {
                reject(`float: serverData missing for non-optional field (${name})`);
            }
        } else {
            const result = Number.parseFloat(serverData);
            if (Number.isNaN(result)) {
                reject(`float: field was not number/was NaN (${name})`);
            } else {
                resolve(result);
            }
        }
    }),
});

export type intResult = number;
export const int = (settings?: CraftTypeSettings): CraftType => ({
    queryExpression: name => name,
    extractData: (serverData, name) => new Promise<floatResult>((resolve, reject) => {
        if (serverData === undefined) {
            if (settings !== undefined && settings.optional) {
                resolve(undefined);
            } else {
                reject(`int: serverData missing for non-optional field (${name})`);
            }
        } else {            
            const result = Number.parseInt(serverData, 10);
            if (Number.isNaN(result)) {
                reject(`int: field was not number/was NaN (${name})`);
            } else {
                resolve(result);
            }
        }
    }),
});

export type SingleAssetSettings = CraftTypeSettings & {
    baseURL?: string,
};
export type singleAsssetResult = string;
export const singleAsset = (settings?: SingleAssetSettings): CraftType => ({
    queryExpression: name => `${name} { url }`,
    extractData: (serverData, name) => new Promise<singleAsssetResult>((resolve, reject) => {
        const optional = settings !== undefined && settings.optional;
        const baseURL = settings !== undefined && settings.baseURL;
        if (serverData === undefined) {
            if (optional) {
                resolve(undefined);
            } else {
                reject(`singleAsset: serverData missing for non-optional field (${name})`);
            }
        } else if (Array.isArray(serverData)) {
            if (serverData.length >= 1) {
                if (serverData.length > 1) {
                    console.warn(`singleAsset: field was longer than one. Max length missing on CMS? (${name})`);
                }

                const value = serverData[0];
                if (typeof value.url === 'string') {
                    if (baseURL) { 
                        resolve(baseURL + value.url); 
                    } else {
                        resolve(value.url);
                    }
                } else {
                    reject(`singleAsset: malformed asset entry, url missing (${name})`);
                }
            } else if (optional) {
                resolve(undefined);
            } else {
                reject(`singleAsset: serverData missing (empty list) for non-optional field (${name})`);
            }
        } else {
            reject(`singleAsset: serverData was not array. (${name})`);
        }
    }),
});

export type uidResult = string;
export const uid = () => text();