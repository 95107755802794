/**
 * API for interfacing with Craft systems and
 * setting up custom data definitions using
 * GraphQL among other things.
 * 
 * This specific file is written to be independent of specific projects.
 * If you have customizations that are specific to a certain projects
 * you need to define those separately.
 * 
 * Typically a project will include CraftAPI
 */


//#region Query Fetcher

// export type QueryFetcher = {
//     get: (query: string) => Promise<any>;
// }


export type ResultType<T> =
    | { status: 'loading' }
    | { status: 'ok', data: T, }
    | { status: 'error', error: any, };

export const setupQueryFetcher = ({ apiURL }: {
    apiURL: string;
}) => ({
    get: ({ query, token }: {
        query: string,
        // Preview token
        token?: string,
    }) => new Promise<any>((resolve, reject) => {
        const url = token ? `${apiURL}?token=${token}` : apiURL;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/graphql',
            },
            body: query,
        }).then(response => {
            if (response.ok) {
                if (response.json) {
                    return response.json();
                } else {
                    console.log(response);
                    reject('No JSON present');
                }
            } else {
                console.log(response);
                reject(`Response not OK: ${response.status}: ${response.statusText}`);
            }
        }).then(responseObject => {
            if (responseObject) {
                const { data, errors } = responseObject;

                if (data !== undefined) {
                    resolve(data)
                } else if (Array.isArray(errors)) {
                    reject(errors.reduce((acc, err) => acc + '\n' + err.message, ''));
                } else {
                    reject('responseObject.data was null/undefined and no error')
                }
            } else {
                reject('responseObject was null');
            }
        }).catch(reason => reject(reason));
    })
});

export type QueryFetcher = ReturnType<typeof setupQueryFetcher>;

//#endregion

//#region Craft Type and Field Set

export interface CraftType {
    // Generate GraphQL expression. Most the time name => name works.
    queryExpression: (name: string) => string;
    // Extract from server data
    extractData: (serverData: any, name: string) => Promise<any>;
};
// Convention, make a type for your result
// using the convention [craftTypeName]Type

export type CraftTypeSettings = {
    optional?: boolean;
}
export const optional = true;

export type FieldDefinition = {
    name: string,
    type: CraftType,
};

export interface FieldSetDefinition {
    readonly typeHandle?: string; // as const

    // @field(type())
    [fieldName: string]: any; // :typeResult

    // Never use _craftField as a field!
    // It is reserved for internal use
    // to make this whole thing work
    // Sorry about this hack.
};

type PrivateFieldSetDefinition = {
    _craftField?: FieldDefinition[];
}

const addCraftType = (
    fieldSetDefinition: FieldSetDefinition,
    fieldDefinition: FieldDefinition
) => {
    // TypeScript don't want me to do this, but then again
    // I didn't want to use classes, so I say we're even.
    const withPrivate = ((fieldSetDefinition as any) as PrivateFieldSetDefinition)
    if (withPrivate._craftField === undefined) {
        withPrivate._craftField = [];
    }
    withPrivate._craftField!.push(fieldDefinition);
}

const getCraftTypes = (
    fieldSetDefinition: FieldSetDefinition
): FieldDefinition[] => {
    const withPrivateFields = ((fieldSetDefinition as any) as PrivateFieldSetDefinition);
    if (withPrivateFields._craftField !== undefined) {
        return withPrivateFields._craftField;
    } else {
        return [];
    }
};

// Decorator factory
export const field = (type: CraftType) => {    
    return (target: FieldSetDefinition, name: string) => {
        addCraftType(target, { name, type })
    }
};

const fieldSetQueryExpression = (
    fieldSetDefinition: FieldSetDefinition
) => getCraftTypes(fieldSetDefinition).reduce((prev, curr) => `${prev}
            ${curr.type.queryExpression(curr.name)}`, '');

const dataFromFieldSet = <D extends FieldSetDefinition>({ fieldSetDefinition, serverData }: {
    fieldSetDefinition: FieldSetDefinition;
    serverData: any;
}) => new Promise<D>((resolve, reject) => {
    const types = getCraftTypes(fieldSetDefinition);
    return Promise.all(
        types.map(f => f.type.extractData(serverData[f.name], f.name)),
    ).then(fieldValues => {
        const fieldResult = Object.fromEntries(
            fieldValues.map((value, i) => [types[i].name, value])
        );
        
        // Mutation, ew...
        if (fieldSetDefinition.typeHandle !== undefined) {
            // typeHandle is specified in a special way and is
            // specifically handled.
            fieldResult['typeHandle'] = fieldSetDefinition.typeHandle;
        }
        
        resolve(fieldResult);
    }).catch(reason => reject(`fieldSetQueryExpression: ${reason+''}`));
});

const filterExpr = (
    filterArguments?: { 
        [argName: string]: string | undefined
    },
) => {
    if (typeof filterArguments !== 'object') { return '' };
    const argList = Object.entries(filterArguments)
                    .filter(e => e[1] !== undefined);
    if (argList.length < 1) { return '' };

    const expr = argList.reduce(
        (prev, curr) => `${prev}${curr[0]}: "${curr[1]}", `, '');
    return `(${expr})`;
}

//#endregion


// class MySectionEntry implements SectionEntry {
//      typeHandle = 'story' as const;

//      @field(text())
//      titleNorwegian!: textResult;
//  }

// class OtherSectionEntry implements SectionEntry {
//     typeHandle = 'poster' as const;

//     @field(singleAsset())
//     image!: singleAsssetResult;
// }

// type SectionType = MySectionEntry | OtherSectionEntry;
// const myFunc = (): SectionType => {
//     return 'das' as any as SectionType;
// }
// const m = myFunc();

// if (m.typeHandle === 'story') {
    
// } else {

// }


// export const entriesQueryExpression = ({ sectionName, fieldSet, entryName, site }: {
//     sectionName: string;
//     fieldSet: FieldSet;
//     entryName?: string;
//     site?: string;
// }): string => {
    
//     // Generate default entryName if not specified
//     const entryNameActual = entryName ? entryName : ` ${sectionName}_${sectionName}_Entry`;

//     // Query args that filter for language if specificed
//     // const siteFilter = site !== undefined ? '(site:"' + site + '")' : '';
    
//     return `{
//     entries${filter({site})} {
//         ...on${entryNameActual} {${fieldSetQueryExpression(fieldSet)}
//     }
// }`;
// };

// export const entriesFromServer = <D extends FieldSet>({ sectionName, fieldSetDef, entryName, fetcher, site }: {
//     sectionName: string;
//     fieldSetDef: new () => D;
//     entryName?: string;
//     fetcher: QueryFetcher;
//     site?: string;
// }): Promise<D[]> => {
//     const fieldSet = new fieldSetDef();

//     return fetcher.get(entriesQueryExpression({ sectionName, fieldSet, entryName, site })).then(data => {
//         if (data.entries && Array.isArray(data.entries)) {
//             if (data.entries.length < 1) {
//                 console.warn(`getEntries ${sectionName}: data.entries had no entries`)
//             }
//             const entryPromises = data.entries.map((e: any) => dataFromFieldSet<D>({
//                 fieldSet,
//                 serverData: e,
//             }));
            
//             return Promise.all(entryPromises).then(value => {
//                 return new Promise(resolve => resolve(value as D[]));
//             });
//         } else {
//             return Promise.reject(`getEntries ${sectionName}: data.entries was null`);
//         }
//     })
// };

// export const globalsQueryExpression = ({ name, fieldSet, site }: {
//     name: string;
//     fieldSet: FieldSet;
//     site?: string;
// }): string => `{
//     globals${siteFilter(site)} {
//         ${name} {${fieldSetQueryExpression(fieldSet)}}
//     }
// }`;

// export const globalFromServer = <D extends FieldSet>({ name, fieldSetDef, fetcher, site }: {
//     name: string;
//     fieldSetDef: new () => D;
//     fetcher: QueryFetcher;
//     site?: string;
// }): Promise<D> => {
//     const fieldSet = new fieldSetDef();

//     return fetcher.get(globalsQueryExpression({ name, fieldSet, site })).then(data => {
//         if (data.globals) {
//             const global = data.globals[name];
//             if (global) {
//                 return dataFromFieldSet({ 
//                     fieldSet, 
//                     serverData: global, 
//                 });
//             } else {
//                 return Promise.reject(`globalFromServer: data.globals.${name} missing`);
//             }
//         } else {
//             return Promise.reject('globalFromServer: data.globals missing');
//         }
//     })
// };


export const _base_private_ = {
    addCraftType,
    getCraftTypes,
    fieldSetQueryExpression,
    dataFromFieldSet,
    filterExpr,
}