import React, { FC, } from 'react'; 
import { Story } from '../../shared/cms-data-setup';
import { stretch } from '../../shared/styles';
import { animated, useSpring, } from 'react-spring'
import { Text } from './Text';
import { Image } from './Image';
import { Video } from './Video';
import { Progress } from './Progress';
import { useActiveChange } from '../../api/util-hooks';

export const imageExts = ['.jpeg', '.jpg', '.png', '.bmp', '.gif', '.svg'];

export const StoryDisplay: FC<{
    story: Story,
    active: boolean,
    progressText: string,
    goLeft: Function,    
    goRight:Function
}> = ({ story, active, progressText, goLeft, goRight }) => {
    const { visualMedia, durationSeconds } = story;
    const visualMediaLower = visualMedia && visualMedia.toLowerCase();
    const isImage = imageExts.some(ext => visualMediaLower !== undefined && visualMediaLower.includes(ext));

    const change = useActiveChange(active, false);

    const toProps = (() => {
        if (change === 'enter-active') { return {
            opacity: 1,
        }} else if (change === 'leave') { return {
            opacity: 0,
        }} else { return { // inactive
            opacity: 0,
        }}
    })();

    const mediaProps = useSpring(toProps);

    const txtProps = useSpring({
        ...toProps,
        // Make txt transition slightly different timed
        // for a sorta parallax effect
        config: { friction: 30, },
    });

    const progProps = useSpring({
        ...toProps,
        config: { friction: 35, },
    });

    return <>
    {
        <animated.div style={mediaProps}>
        {
            isImage
            ?
            <Image
                story={story}
                active={active} />
            :
            <Video
                story={story} 
                active={active} />
        }
        </animated.div> 
    }
        
    {
        <animated.div style={txtProps}>
            <Text story={story} />
        </animated.div>    
    }

    {
        <animated.div style={{
            ...stretch,
            ...progProps,
        }}>
            <Progress 
                key={`${active}`}
                active={active}    
                duration={durationSeconds}
                text={progressText}
                goLeft={goLeft}
                goRight={goRight}
            />
        </animated.div>
    }
    </>;
}
