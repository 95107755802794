import { CSSProperties } from "react";

const isPositive = (n: number) => {
    const s = Math.sign(n);
    return s === 0 || s === 1;
};

export const pos = (
    x: number, y: number
): CSSProperties => ({
    position: 'absolute',
    ...(isPositive(x)
        ?
        {left: x}
        :
        {right: -x }),
    ...(isPositive(y)
        ?
        { top: y }
        :
        { bottom: -y }),
});

export const yellow = '#FFE53B';

export const size = (
    width: number, height: number,
): CSSProperties => ({ width, height, });


export const boxPad = (
    sidesOrAll: number, topBottom?: number,
    ): CSSProperties => topBottom ? {
        boxSizing: 'border-box',
        paddingLeft: sidesOrAll,
        paddingRight: sidesOrAll,
        paddingTop: topBottom,
        paddingBottom: topBottom,
    } : {
        boxSizing: 'border-box',
        padding: sidesOrAll,
    };
    
export const maxWidth = 1920;
export const maxHeight = 1080;

export const appStyle: CSSProperties = {
    position: 'relative',
    width: maxWidth,
    height: maxHeight,
    fontFamily: 'acumin-pro, Acumin Pro, Helvetica Neue, Arial Neue, sans-serif',
    backgroundColor: 'black',
    color: 'white',
    overflow: 'hidden',
};

export const stretch: CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};

export const zNormal = 0;
export const zTopMost = 42;

// Transitions between slides must never take more time than this.
export const transitionTime = 1000;