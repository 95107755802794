import React, { FC, useRef, useEffect, useState, } from 'react'; 
import { Story } from '../../shared/cms-data-setup';
import { pos, size, maxHeight, stretch } from '../../shared/styles';
import { mediaWidth } from './Common';

export const Video: FC<{
    story: Story,
    active: boolean,
}> = ({ story, active }) => {
    const { visualMedia } = story;

    const [play, setPlay] = useState(false);
    useEffect(() => {
        if (active) {
            const t = setTimeout(() => {
                setPlay(true);
            }, 1000);
            return () => clearTimeout(t);
        } else {
            setPlay(false);
        }
    }, [active]);

    const bgVideoRef = useRef<HTMLVideoElement>(null);
    const mainVideoRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        const bgVideo = bgVideoRef.current;
        const mainVideo = mainVideoRef.current;
        if (bgVideo !== null && mainVideo !== null) {
            if (play) {
                bgVideo.play();
                mainVideo.play();
            } else {
                bgVideo.pause();
                mainVideo.pause();
                bgVideo.currentTime = 0;
                mainVideo.currentTime = 0;
            }
        } 
    }, [play]);
    
    return (
        <div style={{
            ...pos(0, 0),
            ...size(mediaWidth, maxHeight),
            backgroundColor: 'red',
        }}>
            {/* Blurry background video in paralell */}
            <div style={{
                ...pos(0, 0),
                ...stretch,
                backgroundColor: 'gray',
                overflow: 'hidden',
            }}>
                <video
                    style={{
                        ...pos(0, 0),
                        transform: 'scale(1.25)',
                        objectFit: 'cover',
                        filter: 'blur(45px)',
                        opacity: 0.75,
                    }}
                    width={mediaWidth}
                    height={maxHeight}
                    src={visualMedia}
                    loop
                    muted
                    playsInline
                    ref={bgVideoRef}
                />
            </div>
            

            {/* Actual Video in the Clear */}
            <video
                style={{
                    ...pos(0, 0),
                }}
                width={mediaWidth}
                height={maxHeight}
                src={visualMedia}
                loop
                muted
                playsInline
                ref={mainVideoRef}
            />
        </div>
    );
};