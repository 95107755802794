import { field, setupQueryFetcher, optional } from "../api/cms-bridge/base";
import { text, textResult, int, intResult, singleAsset, singleAsssetResult, uid, uidResult } from '../api/cms-bridge/types';
import { SectionEntry } from '../api/cms-bridge/section';

export const baseURL = 'https://khcms.no';

export const fetcher = setupQueryFetcher({
    apiURL: `${baseURL}/api`
});


export class Story implements SectionEntry {
    typeHandle = 'story' as const;

    @field(uid())
    uid!: uidResult;

    @field(text())
    dateUpdated!: string;

    @field(text())
    id!: textResult;

    @field(text({ optional }))
    title?: textResult;

    @field(text({ optional }))
    body?: textResult;

    @field(int())
    durationSeconds!: intResult;

    @field(singleAsset({ baseURL, optional }))
    visualMedia?: singleAsssetResult;

    @field(text({ optional }))
    source?: textResult;

    @field(text({ optional }))
    credit?: textResult;
}

export class Poster implements SectionEntry {
    typeHandle = 'poster' as const;

    @field(uid())
    uid!: uidResult;

    @field(text())
    dateUpdated!: string;

    @field(text())
    id!: textResult;

    @field(singleAsset({ baseURL, optional, }))
    image?: singleAsssetResult;

    @field(text({ optional }))
    text?: textResult;

    @field(text())
    verticalAlign!: 'top' | 'middle' | 'bottom';
    // Kinda ugly, ought to be custom field (with validation)

    @field(int())
    durationSeconds!: intResult;
}

export const sectionHandle = 'hvorErViNa';
export const sectionEntries = [ Story, Poster, ];
export type Slide = Story | Poster;