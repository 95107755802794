import { useRef, useEffect } from 'react'

export const usePrevious = <T>(value: T, initial: T) => {
    const ref = useRef(initial);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export const useActiveChange = (
    current: boolean, initial: boolean
): 'inactive' | 'enter-active' | 'leave' => {
    const previous = usePrevious(current, initial);

    if (!previous && !current) { return 'inactive'; }
    else if (!previous && current) { return 'enter-active'; } // enter
    else if (previous && current) { return 'enter-active'; } // staying
    else { return 'leave'; }
}

// Adapted from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
    callback: () => void,
    delay: number,
) => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        if (delay > 0) {
            console.error('useInterval: interval must be positive');
            return;
        }

        const currentCallback = savedCallback.current;
        if (currentCallback !== undefined) {
            const id = setInterval(() => currentCallback(), delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}