import React, { FC, } from 'react'; 
import { Slide } from '../shared/cms-data-setup';
import { StoryDisplay } from './StoryDisplay/StoryDisplay';
import { PosterDisplay } from './PosterDisplay';

export const SlideDisplay: FC<{
    active: boolean,
    slide: Slide,
    progressText: string,
    goLeft: Function,    
    goRight:Function
}> = ({ active, slide, progressText, goLeft, goRight }) => {
    if (slide.typeHandle === 'story') {
        return <StoryDisplay
                    story={slide}
                    active={active}
                    progressText={progressText}
                    goLeft={goLeft}
                    goRight={goRight}
                />
    } else {
        return <PosterDisplay
                    poster={slide}
                    active={active}
                />;
    }
};