import React, { FC, useState, useEffect } from 'react'; 
import { Slide } from '../shared/cms-data-setup';
import { stretch } from '../shared/styles';
import { SlideDisplay } from './SlideDisplay';
import { useSwipeable } from 'react-swipeable';

export const Slideshow: FC<{
    slides: Slide[]
}> = ({ slides }) => {
    const { length } = slides;
    const [activeIndex, setActiveIndex] = useState(0);
    const activeSlide = slides[activeIndex];
    
    const progressText = (index: number) => {
        const stories = slides.filter(s => s.typeHandle === 'story');
        const activeStory = slides[index];
        const storyIndex = stories.findIndex(s => s.id === activeStory.id);
        return `${storyIndex+1}/${stories.length}`;
    };

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => goRight(),
        onSwipedRight: (eventData) => goLeft()
    });

    useEffect(() => {
        const durationMS = activeSlide.durationSeconds * 1000;
        const timeID = setTimeout(() => {
            setActiveIndex(i => (i+1) % length);
        }, durationMS);
        return () => clearTimeout(timeID);
    }, [activeIndex, activeSlide, length])
    

    const goLeft = () => {
        setActiveIndex(i => i > 0 ? i-1 : length-1);
    };

    const goRight = () => {
        setActiveIndex(i => i < length-1 ? i+1 : 0);
    };

    return (
        <div style={stretch} {...handlers}>
        {slides
            .map((slide, index) => 
                <SlideDisplay
                    key={slide.id}
                    active={activeIndex === index}
                    slide={slide}
                    goLeft={goLeft}
                    goRight={goRight}
                    progressText={progressText(index)}
                />
            )
        }
        </div>
    );
};