import React, { FC, } from 'react'; 
import { useEntriesFromServer } from '../api/cms-bridge/section';
import { Slide, sectionHandle, sectionEntries, fetcher } from '../shared/cms-data-setup';
import { Slideshow } from './Slideshow';

export const SlideshowMode: FC = () => {
    
    const entries = useEntriesFromServer<Slide>({
        sectionHandle, sectionEntries, fetcher,
        updateEveryMin: 30,
    });

    
    
    switch (entries.status) {
    case 'loading':
        return <div>Loading…</div>;
    case 'ok':
        // We use this key to determine of any of the entries changed
        // (or was added/removed)
        const updateKey = entries.data.reduce((prev, curr) => prev + curr.dateUpdated , '');
        
        return  <Slideshow slides={entries.data} key={updateKey} />;
    case 'error':
        return <div>Error: {entries.error+''}</div>;
    }
};